import { CodeIcon } from "@heroicons/react/solid";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { projects } from "../data";

const Projects = () => {
  const notify = () =>
    toast(
      "This project is no longer live, please contact me if you'd like to see the GitHub repository."
    );

  return (
    <section id="projects" className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-20">
          <CodeIcon className="mx-auto inline-block w-10 mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
            Apps I've Built
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            These are some projects I have worked on. If they interest you, feel
            free to reach out!
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {projects.map((project) => (
            <a
              href={project.link !== "" ? project.link : "javascript:;"}
              key={project.image}
              target={project.link !== "" ? "_blank" : ""}
              rel="noopener noreferrer"
              className="sm:w-1/2 w-100 h-96 p-4"
              onClick={project.link === "" ? notify : ""}
            >
              <div className="flex relative h-full">
                <img
                  alt="gallery"
                  className="absolute inset-0 w-full h-full object-cover object-center ml-auto mr-auto"
                  src={project.image}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                  <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                    {project.subtitle}
                  </h2>
                  <h1 className="title-font text-lg font-medium text-white mb-3">
                    {project.title}
                  </h1>
                  <p className="leading-relaxed">{project.description}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
        <Toaster
          toastOptions={{
            className: "",
            style: {
              border: "1px solid #713200",
              padding: "16px",
              color: "#713200",
            },
          }}
        />
      </div>
    </section>
  );
};

export default Projects;
